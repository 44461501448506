@import "../../global";

.datePicker {
    // background-color: #222232 !important;
    // border: 1px solid #48556F !important;
    // border-radius: 18px !important;
    // color: white !important;
    // font-size: 16px !important;
    // padding-top: 13px !important;
    // padding-bottom: 13px !important;
    // line-height: 1.4 !important;
    // padding: 10px;
    // margin: 5px 0px 15px 0px;
    width: 100%;
    background-color: $mainBgColor;
    border: 1px solid $primaryColor;
    border-radius: 18px !important;
    color: black !important;
    font-size: 16px !important;

    // padding-top: 13px !important;
    // padding-bottom: 13px !important;
    line-height: 1.4 !important;

    &::placeholder {
        color: black !important;
    }

    &:focus {
        box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
    }
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: none !important;
    border-width: 0px !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
    border-radius: 16px;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    // color: white !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    // color: white !important;
    padding: 14.5px 14px !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input::placeholder {
    // color: white !important;
    opacity: 1 !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important; // work on local
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
    border: none !important; // work on live cpanel
}

.css-egioen-MuiFormControl-root-MuiTextField-root {
    border-radius: 16px !important;
}

.datePicker::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.datePicker::placeholder {
    color: white !important;
}

.datePicker:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem $primaryColorWithAlpha;
}

.css-1k4oq0i-MuiPaper-root-MuiPickersPopper-paper {
    position: relative;
    bottom: 25vh;
}
